import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../../model/apiresponse.model';
import { User } from '../../model/user.model';
import { Observable, map } from 'rxjs';
import { JwtService } from '../utils/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private jwtService: JwtService) { }

  login(email: string, password: string): Observable<ApiResponse<User | null>> {
    return this.http.post<ApiResponse<User | null>>(
      `${environment.loginApiUrl}/auth/login`,
      {
        email: email,
        password: password,
        token_custom_fields: [
          'role'
        ]
      }
    )/* .pipe<ApiResponse<User | null>>(
      map(value => {
        let user = null;
        
        if (value.data !== null) {
          user = new User(
            value.data.id,
            value.data.name,
            value.data.last_name,
            value.data.email,
            value.data.email_verified,
            value.data.picture,
            value.data.role_id,
            value.data.role_name,
            value.data.mfa_enabled,
            value.data.mfa_verified,
            value.data.mfa_type,
            value.data.last_ip,
            value.data.last_login_at,
            value.data.login_count,
            value.data.metadata,
            undefined,
            value.data.disabled,
            value.data.access_token,
            value.data.refresh_token,
            value.data.created_at,
            value.data.updated_at
          );
        }
        
        let apiResponse = new ApiResponse<User | null>(value.code, value.message, user);
        
        return apiResponse;
      })
    ) */;
  }

  passwordReset(email: string): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(
      `${environment.loginApiUrl}/auth/password-reset`,
      {
        email: email
      }
    );
  }

  confirmPasswordReset(email: string | null, otp: string | null, newPassword: string): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(
      `${environment.loginApiUrl}/auth/confirm-password-reset`,
      {
        email: email,
        otp: otp,
        new_password: newPassword
      }
    );
  }

  confirmEmailOTP(email: string | null, otp: string | null): Observable<ApiResponse<User | null>> {
    return this.http.post<ApiResponse<User | null>>(
      `${environment.loginApiUrl}/auth/2fa-email`,
      {
        email: email,
        code: otp,
        token_custom_fields: [
          'role'
        ]
      }
    );
  }

  confirmTOTP(email: string | null, otp: string | null): Observable<ApiResponse<User | null>> {
    return this.http.post<ApiResponse<User | null>>(
      `${environment.loginApiUrl}/auth/2fa-app`,
      {
        email: email,
        code: otp,
        token_custom_fields: [
          'role'
        ]
      }
    );
  }

  sendEmailVerificationMessage(): Observable<ApiResponse<any>> {
    return this.http.get<any>(
      `${environment.loginApiUrl}/auth/send-verify-email`
    );
  }

  verifyEmail(email: string | null, otp: string | null): Observable<ApiResponse<any>> {
    return this.http.post<any>(
      `${environment.loginApiUrl}/auth/verify-email`,
      {
        email: email,
        otp: otp
      }
    );
  }

  refreshToken() {
    let headers = new HttpHeaders();
    let refreshToken = this.jwtService.getRefreshToken();
    if (refreshToken !== null) {
      headers = headers.set('Authorization', refreshToken);
    }
    return this.http.post<any>(
      `${environment.loginApiUrl}/auth/refresh-token`,
      {
        token_custom_fields: [
          'role'
        ]
      },
      {
        headers: headers
      }
    );
  }

  changePassword(password: string, newPassword: string): Observable<ApiResponse<any>> {
    return this.http.put<any>(
      `${environment.loginApiUrl}/auth/change-password`,
      {
        password: password,
        new_password: newPassword
      }
    );
  }

  verify2FA(code: string): Observable<ApiResponse<any>> {
    return this.http.post<any>(
      `${environment.loginApiUrl}/auth/verify-2fa`,
      {
        code: code,
      }
    );
  }
}
